<template>
  <b-sidebar
    position="static"
    :mobile="mobile"
    :expand-on-hover="expandOnHover"
    :reduce="reduce"
    :delay="expandWithDelay ? 500 : null"
    type="is-white"
    open
  >
    <div class="p-1">
      <!--          <div class="block">-->
      <!--            <img-->
      <!--              src="/img/logo_v3.png"-->
      <!--              width="50px"-->
      <!--              alt="Lightweight UI components for Vue.js based on Bulma"-->
      <!--            />-->
      <!--            Minell-->
      <!--          </div>-->
      <b-menu class="is-custom-mobile">
        <b-menu-list :label="`Műveletek ${$store.state.inspection.selected ? $store.state.inspection.selected.status_name : ''}`">
          <SideBarActionItem icon="print"
                             @click.native="$store.dispatch('openModal',
                             {modalName: 'InspectionPrintModal', data: {inspectionID: $route.params.inspectionID}})">
            Nyomtatás
          </SideBarActionItem>
          <SideBarActionItem v-if="Number($store.getters.getSelectedInspectionStatus) === 3"
                             @click.native="checkInspection"
                             icon="lock">
            Ellenőrzés
          </SideBarActionItem>
          <SideBarActionItem v-if="$store.getters.getSelectedInspectionStatus === 3"
                             @click.native="$store.dispatch('openModal', {
                               modalName: 'ConfirmationModal',
                               data: {
                                 text: 'Lezárja az ellenőrzést?',
                                 confirmFunction: () => {
                                   $store.dispatch('closeInspection', $route.params.inspectionID)
                                 },
                               },
                             })"
                             icon="lock">
            Lezárás
          </SideBarActionItem>
          <SideBarActionItem v-if="Number($store.getters.getSelectedInspectionStatus) === 4"
                             @click.native="$store.dispatch('openModal', {
                               modalName: 'InspectionFinalDocumentsAttachModal',
                               data: {
                                 inspectionID: $route.params.inspectionID
                               },
                             })"
                             icon="lock open">
            Dokumentáció csatolás
          </SideBarActionItem>
          <SideBarActionItem v-if="Number($store.getters.getSelectedInspectionStatus) === 4 && $store.getters.minUserLevelGroupLeader"
                             @click.native="$store.dispatch('openModal', {
                               modalName: 'ConfirmationModal',
                               data: {
                                 text: 'Feoldja az ellenőrzést?',
                                 confirmFunction: () => {
                                   $store.dispatch('reOpenInspection', $route.params.inspectionID)
                                 },
                               },
                             })"
                             icon="lock open">
            Feloldás
          </SideBarActionItem>
          <!--          TODO: Beküldés api...-->
          <SideBarActionItem v-if="Number($store.getters.getSelectedInspectionStatus) === 4"
                             @click.native="$store.dispatch('openModal', {
                               modalName: 'ConfirmationModal',
                               data: {
                                 text: 'Beküldi az ellenőrzést?',
                                 confirmFunction: () => {
                                    $store.dispatch('sendInspection', $route.params.inspectionID)
                                    // TODO: redirect
                                 },
                               },
                             })"
                             icon="paper-plane">
            Beküldés
          </SideBarActionItem>
          <SideBarActionItem v-if="Number($store.getters.getSelectedInspectionStatus) <= 4"
                             icon="download"
                             @click.native="openOrDownloadPreviewsDecision">
            Előző határozat
          </SideBarActionItem>
          <SideBarActionItem icon="paper-plane"
                             @click.native="$store.dispatch('openModal', {
                               modalName: 'AttachedDocumentsTreeModal',
                               data: {
                                 attachedDocuments: $store.state.inspection.selected.external_documents
                               }
                             })">
            Online anyagok
          </SideBarActionItem>
          <SideBarActionItem v-if="Number($store.getters.getSelectedInspectionStatus) === 5 && $store.getters.minUserLevelGroupLeader"
                             @click.native="$store.dispatch('openModal', {
                               modalName: 'ConfirmationModal',
                               data: {
                                 text: 'Visszaküldi az ellenőrzést?',
                                 confirmFunction: () => {
                                   $store.dispatch('sendBackInspection', $route.params.inspectionID)
                                 },
                               },
                             })"
                             icon="lock">
            Visszaküldés
          </SideBarActionItem>
          <SideBarActionItem v-if="Number($store.getters.getSelectedInspectionStatus) === 5 && $store.getters.isLawyer"
                             @click.native="$store.dispatch('openModal', {
                                modalName: 'ConfirmationModal',
                                data: {
                                  text: 'Elkészült az ellenőrzés?',
                                  confirmFunction: () => {
                                    reportInspectionFinished()
                                  },
                                },
                             })"
                             icon="flag">
            Készre jelentés
          </SideBarActionItem>
          <SideBarActionItem v-if="Number($store.getters.getSelectedInspectionStatus) === 6 && $store.getters.isLawyer"
                             @click.native="$store.dispatch('openModal', {
                                modalName: 'ConfirmationModal',
                                data: {
                                  text: 'Visszavonja a készre jelentést?',
                                  confirmFunction: () => {
                                    revertInspectionFinished()
                                  },
                                },
                             })"
                             icon="undo">
            Visszavon kész jelentést
          </SideBarActionItem>
          <SideBarActionItem v-if="Number($store.getters.getSelectedInspectionStatus) === 5 && $store.getters.isLawyer"
                             @click.native="$store.dispatch('openModal', {
                               // TODO: confirm modal nem kell, majd ide jöjjön a határozat generálás hívása
                                modalName: 'ConfirmationModal',
                                data: {
                                  text: 'Biztos generálja a határozatot?',
                                  confirmFunction: () => {
                                    // loading...
                                    $store.dispatch('generateInspectionDecision', $route.params.inspectionID)
                                    // loading...
                                  },
                                },
                             })"
                             icon="check-circle">
            Határozat generálás
          </SideBarActionItem>
        </b-menu-list>
        <b-menu-list label="Lapok">
          <b-menu-item icon="table"
                       label="Alapadatok"
                       tag="router-link"
                       :to="{ name: 'InspectionDetails' }"/>
          <b-menu-item v-if="$store.state.inspection.selected.hasOwnProperty('statements')
                              && $store.state.inspection.selected.statements.length > 0"
                       expanded
                       icon="file-alt"
                       tag="router-link"
                       :to="{ name: 'InspectionStatements' }"
                       label="Nyilatkozatok">
            <b-menu-item v-for="statement in $store.state.inspection.selected.statements"
                         :key="statement.id"
                         icon="file-alt"
                         tag="router-link"
                         :title="statement.name"
                         :to="{ name: 'Statement', params: { statementID: statement.id } }"
                         :label="statement.name"/>
          </b-menu-item>
          <b-menu-item v-if="$store.state.inspection.selected.hasOwnProperty('surveys')
                              && $store.state.inspection.selected.surveys.length > 0"
                       expanded
                       icon="tasks"
                       tag="router-link"
                       :to="{ name: 'InspectionSurveys' }"
                       label="Kérdőívek">
            <b-menu-item v-for="survey in $store.state.inspection.selected.surveys"
                         :key="survey.id"
                         icon="tasks"
                         tag="router-link"
                         :title="survey.name"
                         :to="{ name: 'Survey', params: { surveyID: survey.id } }"
                         :label="survey.name"/>
          </b-menu-item>
          <b-menu-item v-if="$store.state.inspection.selected.hasOwnProperty('reports')
                              && $store.state.inspection.selected.reports.length > 0"
                       expanded
                       icon="file-alt"
                       tag="router-link"
                       :to="{ name: 'InspectionReports' }"
                       label="Jegyzőkönyvek">
            <b-menu-item v-for="report in $store.state.inspection.selected.reports"
                         :key="report.id"
                         icon="file-alt"
                         tag="router-link"
                         :title="report.name"
                         :to="{ name: 'Report', params: { reportID: report.id } }"
                         :label="report.name"/>
          </b-menu-item>
          <b-menu-item v-if="[3, 4, 5, 6].includes($store.state.inspection.selected.status_id)"
                       expanded
                       icon="file-alt"
                       tag="router-link"
                       :to="{ name: 'DocumentRegister' }"
                       label="Dokumentumjegyzék"/>
          <b-menu-item v-if="[3, 4, 5, 6].includes($store.state.inspection.selected.status_id)"
                       icon="stream"
                       label="Összesítő"
                       tag="router-link"
                       :to="{ name: 'InspectionSummery' }"/>
          <b-menu-item v-if="$store.state.inspection.selected.hasOwnProperty('final_reports')
                              && $store.state.inspection.selected.final_reports.length > 0"
                       expanded
                       icon="file-alt"
                       tag="router-link"
                       :to="{ name: 'InspectionFinalReports' }"
                       label="Zárójelentések">
            <b-menu-item v-for="report in $store.state.inspection.selected.final_reports"
                         :key="report.id"
                         icon="file-alt"
                         tag="router-link"
                         :title="report.name"
                         :to="{ name: 'FinalReport', params: { reportID: report.id } }"
                         :label="report.name"/>
          </b-menu-item>
        </b-menu-list>
      </b-menu>
    </div>
  </b-sidebar>
  <!--      <div class="p-1">-->
  <!--        <b-field>-->
  <!--          <b-switch v-model="reduce">Reduced</b-switch>-->
  <!--        </b-field>-->
  <!--        <b-field>-->
  <!--          <b-switch v-model="expandOnHover">Expand on hover</b-switch>-->
  <!--        </b-field>-->
  <!--        <b-field>-->
  <!--          <b-switch v-model="expandWithDelay">Hover with delay</b-switch>-->
  <!--        </b-field>-->
  <!--        <b-field label="Mobile Layout">-->
  <!--          <b-select v-model="mobile">-->
  <!--            <option :value="null"></option>-->
  <!--            <option value="reduce">Reduced</option>-->
  <!--            <option value="hide">Hidden</option>-->
  <!--            <option value="fullwidth">Fullwidth</option>-->
  <!--          </b-select>-->
  <!--        </b-field>-->
  <!--      </div>-->
</template>

<script>
import SideBarActionItem from '@/components/Inpsection/SideBar/SideBarActionItem'

export default {
  name: 'SideBar',
  components: { SideBarActionItem },
  data () {
    return {
      expandOnHover: false,
      expandWithDelay: false,
      mobile: 'reduce',
      reduce: false,
    }
  },
  methods: {
    async checkInspection () {
      this.$store.dispatch('startLoading', { loadingKey: 'CHECK_INSPECTION' })
      const checkResponse = await this.$store.dispatch('checkInspection', this.$route.params.inspectionID)
      if (checkResponse.errors.length > 0) {
        this.$store.dispatch('openModal', {
          modalName: 'InspectionErrorsModal',
          data: {
            errors: checkResponse.errors,
          },
        })
      }
      this.$store.dispatch('stopLoading', 'CHECK_INSPECTION')
    },
    async openOrDownloadPreviewsDecision (inspectionID, action = 'open') {
      // loading...
      await this.$store.dispatch('downloadInspectionPreviewsDecision',
        {
          inspectionID: this.$route.params.inspectionID,
          isDownload: false,
        })
      // loading ...
    },
    async reportInspectionFinished () {
      // loading...
      await this.$store.dispatch('reportInspectionFinished', this.$route.params.inspectionID)
      window.location.reload()
      // loading ...
    },
    async revertInspectionFinished () {
      // loading...
      await this.$store.dispatch('revertInspectionFinished', this.$route.params.inspectionID)
      window.location.reload()
      // loading ...
    },
  },
}
</script>

<style lang="scss"
       scoped>
.p-1 {
  padding: 1em;
}

::v-deep.b-sidebar .sidebar-content {
  overflow-y: auto;
  height: calc(100vh - 104px);
  position: sticky;
  top: 0;
}

.menu-list::v-deep a {

  white-space: nowrap !important;
  overflow: hidden;
  text-overflow: ellipsis;

}

@media screen and (max-width: 1023px) {
  .b-sidebar {
    .sidebar-content {
      &.is-mini-mobile {
        &:not(.is-mini-expand),
        &.is-mini-expand:not(:hover):not(.is-mini-delayed) {
          .menu-list {
            li {
              a {
                span:nth-child(2) {
                  display: none;
                }
              }

              ul {
                padding-left: 0;

                li {
                  a {
                    display: inline-block;
                  }
                }
              }
            }
          }

          .menu-label:not(:last-child) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .b-sidebar {
    .sidebar-content {
      &.is-mini {
        &:not(.is-mini-expand),
        &.is-mini-expand:not(:hover):not(.is-mini-delayed) {
          .menu-list {
            li {
              a {
                span:nth-child(2) {
                  display: none;
                }
              }

              ul {
                padding-left: 0;

                li {
                  a {
                    display: inline-block;
                  }
                }
              }
            }
          }

          .menu-label:not(:last-child) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.is-mini-expand {
  .menu-list a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
