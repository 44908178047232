var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"position":"static","mobile":_vm.mobile,"expand-on-hover":_vm.expandOnHover,"reduce":_vm.reduce,"delay":_vm.expandWithDelay ? 500 : null,"type":"is-white","open":""}},[_c('div',{staticClass:"p-1"},[_c('b-menu',{staticClass:"is-custom-mobile"},[_c('b-menu-list',{attrs:{"label":("Műveletek " + (_vm.$store.state.inspection.selected ? _vm.$store.state.inspection.selected.status_name : ''))}},[_c('SideBarActionItem',{attrs:{"icon":"print"},nativeOn:{"click":function($event){return _vm.$store.dispatch('openModal',
                           {modalName: 'InspectionPrintModal', data: {inspectionID: _vm.$route.params.inspectionID}})}}},[_vm._v(" Nyomtatás ")]),(Number(_vm.$store.getters.getSelectedInspectionStatus) === 3)?_c('SideBarActionItem',{attrs:{"icon":"lock"},nativeOn:{"click":function($event){return _vm.checkInspection.apply(null, arguments)}}},[_vm._v(" Ellenőrzés ")]):_vm._e(),(_vm.$store.getters.getSelectedInspectionStatus === 3)?_c('SideBarActionItem',{attrs:{"icon":"lock"},nativeOn:{"click":function($event){_vm.$store.dispatch('openModal', {
                             modalName: 'ConfirmationModal',
                             data: {
                               text: 'Lezárja az ellenőrzést?',
                               confirmFunction: function () {
                                 _vm.$store.dispatch('closeInspection', _vm.$route.params.inspectionID)
                               },
                             },
                           })}}},[_vm._v(" Lezárás ")]):_vm._e(),(Number(_vm.$store.getters.getSelectedInspectionStatus) === 4)?_c('SideBarActionItem',{attrs:{"icon":"lock open"},nativeOn:{"click":function($event){return _vm.$store.dispatch('openModal', {
                             modalName: 'InspectionFinalDocumentsAttachModal',
                             data: {
                               inspectionID: _vm.$route.params.inspectionID
                             },
                           })}}},[_vm._v(" Dokumentáció csatolás ")]):_vm._e(),(Number(_vm.$store.getters.getSelectedInspectionStatus) === 4 && _vm.$store.getters.minUserLevelGroupLeader)?_c('SideBarActionItem',{attrs:{"icon":"lock open"},nativeOn:{"click":function($event){_vm.$store.dispatch('openModal', {
                             modalName: 'ConfirmationModal',
                             data: {
                               text: 'Feoldja az ellenőrzést?',
                               confirmFunction: function () {
                                 _vm.$store.dispatch('reOpenInspection', _vm.$route.params.inspectionID)
                               },
                             },
                           })}}},[_vm._v(" Feloldás ")]):_vm._e(),(Number(_vm.$store.getters.getSelectedInspectionStatus) === 4)?_c('SideBarActionItem',{attrs:{"icon":"paper-plane"},nativeOn:{"click":function($event){_vm.$store.dispatch('openModal', {
                             modalName: 'ConfirmationModal',
                             data: {
                               text: 'Beküldi az ellenőrzést?',
                               confirmFunction: function () {
                                  _vm.$store.dispatch('sendInspection', _vm.$route.params.inspectionID)
                                  // TODO: redirect
                               },
                             },
                           })}}},[_vm._v(" Beküldés ")]):_vm._e(),(Number(_vm.$store.getters.getSelectedInspectionStatus) <= 4)?_c('SideBarActionItem',{attrs:{"icon":"download"},nativeOn:{"click":function($event){return _vm.openOrDownloadPreviewsDecision.apply(null, arguments)}}},[_vm._v(" Előző határozat ")]):_vm._e(),_c('SideBarActionItem',{attrs:{"icon":"paper-plane"},nativeOn:{"click":function($event){return _vm.$store.dispatch('openModal', {
                             modalName: 'AttachedDocumentsTreeModal',
                             data: {
                               attachedDocuments: _vm.$store.state.inspection.selected.external_documents
                             }
                           })}}},[_vm._v(" Online anyagok ")]),(Number(_vm.$store.getters.getSelectedInspectionStatus) === 5 && _vm.$store.getters.minUserLevelGroupLeader)?_c('SideBarActionItem',{attrs:{"icon":"lock"},nativeOn:{"click":function($event){_vm.$store.dispatch('openModal', {
                             modalName: 'ConfirmationModal',
                             data: {
                               text: 'Visszaküldi az ellenőrzést?',
                               confirmFunction: function () {
                                 _vm.$store.dispatch('sendBackInspection', _vm.$route.params.inspectionID)
                               },
                             },
                           })}}},[_vm._v(" Visszaküldés ")]):_vm._e(),(Number(_vm.$store.getters.getSelectedInspectionStatus) === 5 && _vm.$store.getters.isLawyer)?_c('SideBarActionItem',{attrs:{"icon":"flag"},nativeOn:{"click":function($event){_vm.$store.dispatch('openModal', {
                              modalName: 'ConfirmationModal',
                              data: {
                                text: 'Elkészült az ellenőrzés?',
                                confirmFunction: function () {
                                  _vm.reportInspectionFinished()
                                },
                              },
                           })}}},[_vm._v(" Készre jelentés ")]):_vm._e(),(Number(_vm.$store.getters.getSelectedInspectionStatus) === 6 && _vm.$store.getters.isLawyer)?_c('SideBarActionItem',{attrs:{"icon":"undo"},nativeOn:{"click":function($event){_vm.$store.dispatch('openModal', {
                              modalName: 'ConfirmationModal',
                              data: {
                                text: 'Visszavonja a készre jelentést?',
                                confirmFunction: function () {
                                  _vm.revertInspectionFinished()
                                },
                              },
                           })}}},[_vm._v(" Visszavon kész jelentést ")]):_vm._e(),(Number(_vm.$store.getters.getSelectedInspectionStatus) === 5 && _vm.$store.getters.isLawyer)?_c('SideBarActionItem',{attrs:{"icon":"check-circle"},nativeOn:{"click":function($event){_vm.$store.dispatch('openModal', {
                             // TODO: confirm modal nem kell, majd ide jöjjön a határozat generálás hívása
                              modalName: 'ConfirmationModal',
                              data: {
                                text: 'Biztos generálja a határozatot?',
                                confirmFunction: function () {
                                  // loading...
                                  _vm.$store.dispatch('generateInspectionDecision', _vm.$route.params.inspectionID)
                                  // loading...
                                },
                              },
                           })}}},[_vm._v(" Határozat generálás ")]):_vm._e()],1),_c('b-menu-list',{attrs:{"label":"Lapok"}},[_c('b-menu-item',{attrs:{"icon":"table","label":"Alapadatok","tag":"router-link","to":{ name: 'InspectionDetails' }}}),(_vm.$store.state.inspection.selected.hasOwnProperty('statements')
                            && _vm.$store.state.inspection.selected.statements.length > 0)?_c('b-menu-item',{attrs:{"expanded":"","icon":"file-alt","tag":"router-link","to":{ name: 'InspectionStatements' },"label":"Nyilatkozatok"}},_vm._l((_vm.$store.state.inspection.selected.statements),function(statement){return _c('b-menu-item',{key:statement.id,attrs:{"icon":"file-alt","tag":"router-link","title":statement.name,"to":{ name: 'Statement', params: { statementID: statement.id } },"label":statement.name}})}),1):_vm._e(),(_vm.$store.state.inspection.selected.hasOwnProperty('surveys')
                            && _vm.$store.state.inspection.selected.surveys.length > 0)?_c('b-menu-item',{attrs:{"expanded":"","icon":"tasks","tag":"router-link","to":{ name: 'InspectionSurveys' },"label":"Kérdőívek"}},_vm._l((_vm.$store.state.inspection.selected.surveys),function(survey){return _c('b-menu-item',{key:survey.id,attrs:{"icon":"tasks","tag":"router-link","title":survey.name,"to":{ name: 'Survey', params: { surveyID: survey.id } },"label":survey.name}})}),1):_vm._e(),(_vm.$store.state.inspection.selected.hasOwnProperty('reports')
                            && _vm.$store.state.inspection.selected.reports.length > 0)?_c('b-menu-item',{attrs:{"expanded":"","icon":"file-alt","tag":"router-link","to":{ name: 'InspectionReports' },"label":"Jegyzőkönyvek"}},_vm._l((_vm.$store.state.inspection.selected.reports),function(report){return _c('b-menu-item',{key:report.id,attrs:{"icon":"file-alt","tag":"router-link","title":report.name,"to":{ name: 'Report', params: { reportID: report.id } },"label":report.name}})}),1):_vm._e(),([3, 4, 5, 6].includes(_vm.$store.state.inspection.selected.status_id))?_c('b-menu-item',{attrs:{"expanded":"","icon":"file-alt","tag":"router-link","to":{ name: 'DocumentRegister' },"label":"Dokumentumjegyzék"}}):_vm._e(),([3, 4, 5, 6].includes(_vm.$store.state.inspection.selected.status_id))?_c('b-menu-item',{attrs:{"icon":"stream","label":"Összesítő","tag":"router-link","to":{ name: 'InspectionSummery' }}}):_vm._e(),(_vm.$store.state.inspection.selected.hasOwnProperty('final_reports')
                            && _vm.$store.state.inspection.selected.final_reports.length > 0)?_c('b-menu-item',{attrs:{"expanded":"","icon":"file-alt","tag":"router-link","to":{ name: 'InspectionFinalReports' },"label":"Zárójelentések"}},_vm._l((_vm.$store.state.inspection.selected.final_reports),function(report){return _c('b-menu-item',{key:report.id,attrs:{"icon":"file-alt","tag":"router-link","title":report.name,"to":{ name: 'FinalReport', params: { reportID: report.id } },"label":report.name}})}),1):_vm._e()],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }