<template>
  <div class="sidebar-page">
    <section v-if="hasSelectedInspection" class="sidebar-layout">
      <SideBar/>
      <router-view class="sidebar-content mr-5"/>
    </section>
  </div>
</template>

<script>
import SideBar from '@/components/Inpsection/SideBar/SideBar'
import { mapGetters } from 'vuex'

export default {
  name: 'Inspection',
  components: { SideBar },
  data () {
    return {}
  },
  computed: {
    ...mapGetters(['hasSelectedInspection'])
  },
  beforeCreate () {
    // TODO: await
    // TODO: loading
    this.$store.dispatch('selectInspection', Number(this.$route.params.inspectionID))
  }
}
</script>

<style lang="scss"
       scoped>
.sidebar-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  // min-height: 100vh;
  .sidebar-layout {
    display: flex;
    flex-direction: row;
    min-height: 100%;
    // min-height: 100vh;
  }

  .sidebar-content {
    width: 100%;
  }
}
</style>
